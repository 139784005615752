<template>
  <div id="proctoring">
    <div>
      <div>
        <h3>Proctoring photos ({{ `First ${payload.limit} matching results` }})</h3>
      </div>
      <div class="proctoring-form">
        <validation-observer style="width:100%" ref="form" v-slot="{ handleSubmit, invalid }">
          <form @submit.prevent="handleSubmit(fetchProctored)">
            <validation-provider style="width:100%" rules="uuid" name="User ID" v-slot="{ errors }">
              <div class="form-row" :class="{ 'error': errors.length > 0 }">
                <h4 style="font-size: 1em">Filter by User Id</h4>
                <div style="display:flex;">
                  <input id="txtUserID" style="flex:0; margin-right: 10pt" type="text" :disabled="isBusy" placeholder="Enter User ID" v-model="userID">
                  <p v-if="errors.length > 0" class="error">
                    {{ errors[0] }}
                  </p>
                  <p v-if="!isUserIDValid && !errors.length" class="error">Invalid User ID</p>
                  <button class="es-btn primary small" style="margin:auto; margin-left: 0" :disabled="invalid || isBusy">Filter</button>
                  <es-btn class="es-btn primary small" style=" margin:auto; margin-right:0;" @click="fetchProctored" color="teal">Refresh results <fa icon="sync" /></es-btn>
                </div>
              </div>
            </validation-provider>
          </form>
        </validation-observer>
      </div>
      <div class="filters">
        <div class="status-filter">
          <div class="status-dropdown" v-for="(filter, index) in filters" :key="index">
            <p style="font-weight:bold">{{filter.label}}</p>
            <div class="filter-selector" @click="filter.toggle = !filter.toggle">
              <label class="title">Showing: <strong>{{filter.showing}}</strong></label>
              <div class="down-arrow"></div>
            </div>
             <transition name="fade">
              <div v-if="filter.toggle" class="drop">
                <div class="default">
                  <div class="filter-button">
                    <input :id="`status_show_all_${index}`" v-model="filter.selected" value="All" type="radio" class="d-none">
                    <label :for="`status_show_all_${index}`" class="button">Show all</label>
                  </div>
                </div>
                <div class="options-container">
                  <p>Show only (can select one)</p>
                  <div class="options" v-for="(option, index) in filter.options" :key="index">
                    <div class="filter-button">
                      <input :id="`status_${option.key}`" v-model="filter.selected" :value="option.key" type="radio" class="d-none">
                      <label :for="`status_${option.key}`" class="button">{{option.displayName}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <es-btn id="clear-btn" :disabled="showAll" @click="clearFilters" color="indigo">Clear filters</es-btn>
      </div>
    </div>
    <div class="row">
      <div v-if="isBusy" class="col-sm-12">
          <div class="loading">
            <h4>Please wait ...</h4>
            <p>Fetching proctorings</p>
          </div>
      </div>
      <div v-if="!isBusy && lazyItems.length === 0 || error"  class="col-sm-12">
        <p>{{ searchResultMessage }}</p>
      </div>
      <div v-if="!isBusy" class="col-sm-12">
        <template v-for="(item, $index) in proctoringItems">
          <div v-if="item" :key="`lazy_proctoring_item_${item.user_id}_${item.order_id}_${$index}`" class="proctoring-item" :style="{'--borderColor': `${statusMap[item.status].borderColour || 'null'}`}">
            <div>
              <div class="details">
                <div><strong>user_id</strong> : <router-link :to="{ path: '/users', query: { search: item.user_id } }">{{ item.user_id }}</router-link></div>
                <div><strong>sitting_id</strong> : <router-link :to="{ path: '/sittings', query: { user_id: item.user_id } }">{{ item.sitting_id }}</router-link></div>
                <div><strong>order_id</strong> : {{ item.order_id }}</div>
                <div><strong>country</strong> : {{ getCountryName(item.user_country) }}</div>
                <div><strong>time</strong> : <a :href="item.user_events_href" target="_blank" title="See events for this user">{{ item.sitting_started_at | dateString }}</a></div>
                <div><span v-if="item.tier"><strong> tier</strong> : {{ item.tier }}</span></div>
                <div><span v-if="item.type"><strong> type</strong> : {{ item.type }}</span></div>
                <div><router-link :to="item.user_events_href">User Event</router-link></div>
              </div>
              <div class="actions">
                <div>
                  <div class="label">Status:</div>
                  <div class="buttons">
                    <div v-for="(mapItem, index) in statusMap" :key="index">
                      <input type="radio" :id="`status_${$index + mapItem.name}`" :value="index" :name="`status_radio_${item.order_id}_${item.sitting_id}`" :disabled="isStatusBusy || disableActions(item)" style="visibility:hidden" :class="{'checked' : item.status === index}" @click.prevent="setStatus({itemIndex: $index, statusIndex: index})">
                      <label :for="`status_${$index + mapItem.name}`" class="button" :class="{ 'disabled': isStatusBusy || disableActions(item) }" :style="{'--statusColor': `${mapItem.colour}`}">{{mapItem.name}}</label>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="label">Reason:</div>
                  <div class="buttons" v-if="statusMap[item.status].reasons.length > 0">
                    <div v-for="(reason, index) in statusMap[item.status].reasons" :key="index">
                      <input :id="`reason_${$index + reason.title}`" type="radio" :value="index" :name="`reason_radio_${item.order_id}_${item.sitting_id}`" :disabled="isStatusBusy || disableActions(item)" style="visibility:hidden" :class="{'checked' : item.reason === index}" @click.prevent="setReason(reason, {itemIndex: $index, statusIndex: item.status, reasonIndex: index, action: reason.action})">
                      <label :for="`reason_${$index + reason.title}`" class="button" :class="{ 'disabled': isStatusBusy || disableActions(item)}" :style="{'--statusColor': `${statusMap[item.status].colour}`}">{{reason.title}}</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="images">
                <lazy-proctoring-images :user-id="item.user_id" :sitting-id="item.sitting_id" />
              </div>

            </div>
          </div>
        </template>
      </div>

      <transition name="fade">
        <es-modal :show-icon="false" v-if="showSureModal" @close="deselectReason" class="sure-modal" >
          <div slot="content">
            <p style="text-align:left; margin-left:auto !important; margin-top:0; padding:0">Marking this sitting as <strong> {{ failModalReason }} </strong> will cancel this test-takers sitting.</p>
          </div>

          <div slot="actions" class="sure-modal-actions">
            <h4 style="color: var(--dark-indigo)">Are you sure?</h4>
            <es-tap-btn :full="false" @click="continueCancelSitting" color="orange">Yes, cancel this sitting</es-tap-btn>
            <es-tap-btn :full="false" @click="deselectReason" color="grey" style="color:var(--dark-indigo)">No, go back</es-tap-btn>
          </div>
        </es-modal>
      </transition>

    </div>
  </div>
</template>
<script>


import { mapActions, mapGetters } from 'vuex'
import LazyProctoringImages from '@/components/proctoring-oldversion/LazyProctoringImages'
import ValidateUUID from 'uuid-validate'
import { DateTime } from "luxon";

export default {
  name: 'Proctoring',
  components: {
    LazyProctoringImages,
  },
  computed: {
    ...mapGetters({
      lazyItems: 'proctoringOldversion/lazyProctoringItems',
      getCountryName: 'country/getCountryName',
      country: 'country/country',
      isBusy: 'proctoringOldversion/isBusy',
      error: 'proctoringOldversion/error',
      isStatusBusy: 'proctoringOldversion/isStatusBusy',
      statusMap: 'proctoringOldversion/statusMap',
    }),
    proctoringItems() {
      return this.lazyItems.map(item => {
        const { user_id, sitting_started_at } = item;
        const formattedDate = DateTime.fromISO(sitting_started_at).toFormat('yyyy,MM,dd');
        const user_events_href = `/user-events?uid=${user_id}&ed=${formattedDate}`;
        return {
          ...item,
          user_events_href
        };
      });
    },
    selected() {
      return this.filters.map(filter => filter.selected)
    },
    showAll: {
      get () {
        return !this.filters.some((x) => x.selected != 'All') && !this.userID.length > 0
      },
      set(value) {
        if (value) {
          this.userID = ''
          this.filters.forEach(element => {
            element.selected = 'All',
            element.toggle = false
          });
        }
      }
    },
    searchResultMessage(){
      if(this.error)
        return 'Error fetching proctoring information'
      if(this.lazyItems.length === 0)
        return 'No proctoring images match the filters'
      return 'Unknown error'
    }
  },
  data: () => ({
    userID: '',
    payload: {
      limit: 200,
      orders_only: true,
      connect_code_only: true,
      status: 'unreviewed',
      certificate_status: 'available',
    },
    showSureModal: false,
    cancelSittingQueue: null,
    filters: [
      {
        name: 'proctoring',
        label: 'Filter by proctoring status',
        options: [
          {
            key:'unreviewed', displayName: 'Unreviewed',
          },{
            key:'pass_great', displayName: 'Pass - Great',
          },{
            key:'pass_acceptable', displayName: 'Pass - Acceptable',
          },{
            key:'fail_not_visible', displayName: 'Fail - Not visible',
          },{
            key:'fail_not_alone', displayName: 'Fail - Not alone',
          },{
            key:'fail_not_matching', displayName: 'Fail - Not matching',
          },{
            key:'fail_main_photo_issue', displayName: 'Fail - Main photo issue',
          },{
            key:'flag_tech_issue', displayName: 'Flag - Tech issue',
          },{
            key:'flag_possible_underage', displayName: 'Flag - Possible under-age',
          },{
            key:'flag_other', displayName: 'Flag - Other',
          }
        ],
        defaultText: 'Show only (can only select one)',
        selected: 'unreviewed',
        toggle:false
      },
      {
        name: 'certificate_status',
        label: 'Filter by certificate status',
        options: [
          {
            key:'available', displayName: 'Certificate Available',
          },
          {
            key:'pending', displayName: 'Certificate Pending',
          },
        ],
        defaultText: 'Show only (can only select one)',
        selected: 'available',
        toggle:false
      },
      {
        name: 'connect_code_status',
        label: 'Filter by Connect Code status',
        options: [
          {
            key:'has_code', displayName: 'Used Connect Code',
          },{
            key:'no_code', displayName: 'No Connect Code',
          }
        ],
        defaultText: 'Show only (can only select one)',
        selected: 'All',
        toggle:false
      },
    ],
  }),
  methods: {
    ...mapActions({
      fetchLazyItems: 'proctoringOldversion/fetchLazyList',
      setStatus: 'proctoringOldversion/setStatus'
    }),
    fetchProctored() {
      const proctoringFilter = this.filters.find(filter => filter.name == 'proctoring')
      if (proctoringFilter.selected === 'All') {
        delete this.payload.status
        proctoringFilter.showing = 'All'
      } else {
        this.payload.status = proctoringFilter.selected
        proctoringFilter.showing = proctoringFilter.options.find(option => option.key === proctoringFilter.selected).displayName
      }

      const certificateFilter  = this.filters.find(filter => filter.name === 'certificate_status')
      if (certificateFilter.selected === 'All') {
        delete this.payload.certificate_status
        certificateFilter.showing = 'All'
      } else {
        this.payload.certificate_status = certificateFilter.selected
        certificateFilter.showing = certificateFilter.options.find(option => option.key === certificateFilter.selected).displayName
      }

      const connectCodeFilter  = this.filters.find(filter => filter.name === 'connect_code_status')
      if (connectCodeFilter.selected === 'All') {
        delete this.payload.connect_code_only
        connectCodeFilter.showing = 'All'
      } else {
        this.payload.connect_code_only = connectCodeFilter.selected === 'has_code' ? true : false
        connectCodeFilter.showing = connectCodeFilter.options.find(option => option.key === connectCodeFilter.selected).displayName
      }

      if (this.userID.length > 0) {
        this.payload['user_id'] = this.userID
      }
      else {
        delete this.payload.user_id
      }

      this.fetchLazyItems(this.payload)
    },
    isUserIDValid() {
      if (!this.userID) return true
      return ValidateUUID(this.userID, 4)
    },
    clearFilters() {
      this.showAll = true
    },
    updateQueryParams () {
      let queryChanged = false
      const query = {
        ...this.$route.query
      }
      if (this.userID.length > 0) {
        queryChanged = true
        query.user_id = this.userID
      } else {
        delete query.user_id
        queryChanged = true
        this.fetchProctored()
      }
      if (this.eventDate) {
        queryChanged = true
        query.ed = `${this.eventDate.getFullYear()},${(this.eventDate.getMonth() + 1).toString().padStart(2, '0')},${this.eventDate.getDate().toString().padStart(2, '0')}`
      }
      if (this.showHidden) {
        queryChanged = true
        query.sh = this.showHidden
      }
      if (queryChanged) {
        this.$router.replace({
          query
        }).catch(error => {
          console.warn(error)
        })
      }
    },
    setReason(reason, payload) {
      if (reason.confirmBeforeApply) {
        this.cancelSittingQueue = payload;
        this.showModal(reason.title);
      } else {
        this.setStatus(payload);
      }
    },
    showModal(reason){
      this.showSureModal = true;
      this.failModalReason = reason;
    },
    continueCancelSitting() {
      this.setStatus(this.cancelSittingQueue);

      this.showSureModal = false;
      this.cancelSittingQueue = null
    },
    deselectReason(){
      this.showSureModal = false;
      this.cancelSittingQueue = null
    },
    disableActions(item){
      return item.action == 'invalidate'
    }
  },
  beforeMount() {
    this.userID = this.$route.query.user_id || ''
    this.fetchProctored()
  },
  watch: {
    userID() {
      this.updateQueryParams()
    },
    selected() {
      this.fetchProctored()
      this.filters.forEach((filter) => {
        setTimeout(() => {
          filter.toggle = false;
        }, 100)
      })
    },
  }
}
</script>
<style lang="scss">
.modal-wrapper{
  max-width: 750px;
  text-align: left !important;
  padding: 15pt !important;
  & * {
    width: 100%;
  }
}
</style>
<style lang="scss" scoped>
.d-none {
  display: none;
}
#proctoring {
  .proctoring-form {
    background: white;
    padding: 0 1em;
    display: flex;
    flex-direction: revert;
    justify-content: space-between;
    align-items: flex-end;

    & form {
      display: flex;
      flex-direction: row;

      & .form-row {
        display: flex;
        flex-direction: column;

        & label {
          display: block;
          font-size: 12px;
          cursor: pointer;

          & svg {
            color: $cranberry;
            width: 8px;
            vertical-align: top;
            margin-left: .25em;
          }
        }
        &.error {
          & input {
            border-color: $cranberry;
          }
        }
        & input {
          width: 100%;
          min-width: 300px;
          margin-top: .25em;
          padding: .5em;
          outline: none;
          border-radius: 4px;
          border: 1px solid #ccc;
          font-size: 14px;

          &:focus {
            border-color: $dark-indigo;
          }
        }
        & p.error {
          font-size: 14px;
          color: $cranberry;
          margin-top: .25em;
          margin-bottom: 0;
        }
      }

      & button {
        margin-top: 20px;
        height: 36px;
        align-self: top;
      }
    }
  }
}

.row {
  width: 100%;
  margin: auto;
}

.filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  padding: 1em;
  overflow-x: auto;

  & .status-filter{
    display: flex;

    & .status-dropdown{
      margin-right: 40pt;
      & p {
        margin-bottom: 10pt;
      }

      & .filter-selector {
        display: flex;
        background: var(--dark-indigo);
        color: white;
        border-radius: 4pt;
        padding: 6.5pt 10pt 6.5pt 14pt;
        width: fit-content;
        min-width: 200pt;
        cursor: pointer;

        & label {
          cursor: pointer;
          flex: 1;
          padding-right: 10pt;
        }

        & .down-arrow {
          margin: auto;
          margin-right: 3pt;
          margin-top: 6pt;
          width: 0;
          height: 0;
          border-left: 5.2pt solid transparent;
          border-right: 5.2pt solid transparent;
          border-top: 5.5pt solid rgb(255, 255, 255);
        }
      }
      & .drop {
        position:absolute;
        margin-top: 5pt;
        min-width: 230pt;
        background: #f3f3f3;
        box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.6);
        border-radius: 2pt;

        & .default {
          padding: 15pt;
          border-bottom: 1pt solid #d8d8d8;
        }

        & .filter-button{
          display: flex;
          flex-direction: column;

          & label {
            background: white;
            width: fit-content;
            text-align: center !important;
            border: solid 1px #d8d8d8;
            border-radius: 2pt;
            padding: 0pt 10pt 0pt 10pt;
            cursor: pointer;
          }

          & input[type="radio"]:checked + label {
            background: var(--dark-indigo) !important;
            border: 1pt solid var(--dark-indigo) !important;
            color: white;
          }
        }

        & .options-container {
          padding: 10pt 10pt 15pt 15pt;
          & p {
            margin: 0;
            font-size: 10pt;
          }

          & .options {
            display: flex;
            flex-direction: column;

            &  > div {
              margin-top: 7pt;
            }
          }
        }

      }
    }
  }


  & button.es-btn {
    font-size: small;
    padding: .5em 1em;
    &:not(:last-child) {
      margin-right: .5em;
    }
    & svg {
      margin-right: .5em;
    }
    &:active {
      opacity: .6;
    }
  }

  #clear-btn {
    margin-top: auto;
    font-size: 1em;
    padding: 9pt 12pt 9pt 12pt;
  }


}

.proctoring-item {
  margin: 1.5em 0.5em;
  border: 3pt solid var(--borderColor);
  border-radius: 4pt;
  padding: 15pt;
  &>div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .details {
    margin-bottom: .5em;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &>div {
      &:not(:last-child) {
        &::after {
          content: '|';
          margin: 0 .5em;
        }
      }
    }
  }
  .images {
    overflow: scroll;
  }
  & .actions {
    padding: 10pt 0 3pt 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    & > div {
      display: flex;
      margin-bottom: 12pt;
      & > .label{
        font-weight: bold;
        width: 60pt;
        margin-top: auto;
        margin-bottom: auto;
      }
      & > input{
        flex: 1;
        margin: auto;
        padding: 5pt;
        border-radius: 3pt;
        border: 1pt solid #c9c9c9;
        margin-right: 10pt;
        margin-left: 15pt;
      }
      & > .buttons{
        display: flex;
        & > div {
          & > .button {
              font-weight: bold;
              font-size: smaller;
              margin-right: 10pt;
              padding: 4pt 8pt 4pt 8pt;
              border-radius: 4pt;
              border: 1pt solid #e6e6e6;
              cursor: pointer;

          }
          & input[type="radio"].checked + label {
            background: var(--statusColor) !important;
            border: 1pt solid var(--statusColor) !important;
          }
          & label {
            &.disabled {
              opacity: .4;
            }
          }
        }
      }

    }
    & button {
      padding: .5em 1em;
      font-size: small;
      margin-right: .5em;
      &.active {
        background: var(--sunflower-yellow);
      }
    }
  }
  &:not(:last-child) {
    padding-bottom: .5em;
  }
}
.sure-modal-actions{
  display: flex;
  margin: auto;
  width: 100%;
  & h4 {
    margin: auto;
    margin-bottom: 13pt;
    flex: 1 !important;

  }

  & button  {
    width: fit-content !important;
    margin: auto !important;
    margin-bottom: 3pt;
    margin-left: 10pt !important;
  }
}
</style>


