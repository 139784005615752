<template>
  <div>
    <div class="lazy-images" ref="images"></div>
  </div>
</template>
<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import NoImage from './../../assets/images/no-image.png'
import NoProfilePicture from './../../assets/images/no-profile-picture.png'

export default {
  name: 'LazyProctoringImages',
  props: ['userId', 'sittingId'],
  async mounted() {
    try {
      const storage = firebase.app().storage(process.env.VUE_APP_PROCTORING_BUCKET)
      const imageDirRef = storage.ref(`${this.userId}/${this.sittingId}`)
      
      // Official certificate photo:
      const img = document.createElement('img')
      const imageRef = imageDirRef.child('photo_001.jpg')
      img.alt = 'Official certificate photo'

      try {
          const imageUrl = await imageRef.getDownloadURL()
          img.src = imageUrl
      } catch(err) {
          img.src = NoProfilePicture
      }
        
      this.$refs.images.appendChild(img)

      // Proctoring photos:
      for (let i=0; i<7; i++) {
        const div = document.createElement('div');
        const img = document.createElement('img');
        const name = `proctoring_${ Number(i + 1).toString().padStart(3, '0') }.jpg`
        const imageRef = imageDirRef.child(name)
        let proctoringViolations = null;

        img.alt = name
        try {
          const meta = await imageRef.getMetadata()
          proctoringViolations = JSON.parse(meta.customMetadata?.proctoringViolations ?? '[]')
          const imageUrl = await imageRef.getDownloadURL()
          img.src = imageUrl
        } catch(err) {
          img.src = NoImage
        }
        
        div.appendChild(img);
        if (proctoringViolations && proctoringViolations.length > 0) {
          const span = document.createElement('span');
          span.innerText = proctoringViolations.join(' ,');
          div.appendChild(span);
        }
        this.$refs.images.appendChild(div)
      }
      
    } catch(err) {
      // console.log(err)
    }
  },
  
}
</script>
<style lang="scss">
.lazy-images {
  display: flex;
  flex-direction: row;
  & > img {
    width: 100%;
    height: 133.33px;
    object-fit: cover;
    border-right: 1px solid #eee;
    padding-right: 1em;
    width: calc(100px + 1em)
  }
  & div {
    width: 100px;
    display: flex;
    flex-direction: column;
    margin-right: 1em;
    // border: 2px solid red;
    box-sizing: border-box;
    flex-wrap: wrap;

    & img {
      object-fit: cover;
      height: 133.33px;
      width: 100%;
    }
    & span {
      margin-top: .4em;
      font-size: 9px;
      overflow-wrap: anywhere;
    }

    // &:first-child {
    //   border-right: 1px solid #eee;
    //   padding-right: 1em;
    //   width: calc(100px + 1em)
    // }
  }
}
</style>
